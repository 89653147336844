import './style.css';
import Map from 'ol/Map.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';

import View from 'ol/View.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import {Icon, Style} from 'ol/style.js';

import XYZ from 'ol/source/XYZ.js';
import WMTS from 'ol/source/WMTS.js';

import {Attribution, defaults as defaultControls} from 'ol/control.js';
import {fromLonLat, toLonLat} from 'ol/proj.js';

import {defaults as defaultInteractions} from 'ol/interaction/defaults';

import {getCenter} from 'ol/extent.js';


//console.log("STORED: " + localStorage.lastname)
//localStorage.setItem("lastname", "Smith")

var tempElement = document.createElement("input")
//var temperatureCoord = [0, 0]
var temperatureCoord = new Date(0)

getLocation(showPosition)

/*
var intervalId = setInterval(function() {
  console.log("Periodic!")
}, 5000);
*/

const attribution = new Attribution({
  collapsible: false,
});

const maxZoom = 15

var basemaps = []
var features = []

const iconFeature = new Feature({
  geometry: new Point(fromLonLat([0, 0])),
  name: 'User location',
});

const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: 'assets/location.png',
  }),
})

iconFeature.setStyle(iconStyle)

const icons = [
	{
		label: "icons",
		tileLayer: new VectorLayer({
			visible: true,
			opacity: 1,
			source: new VectorSource({
				features: [iconFeature],
			}),
		})
	}
]


fetch('/definitions/basemaps.json').then(async response => {
	basemaps = (await response.json()).filter(l => l.hidden !== true).map(l => {
		return {
			label: l.label,
			tileLayer: new TileLayer({
				visible: false,
				opacity: l.opacity || 1,
				source: new XYZ({
					url: l.url,
					attributions: l.attributions
				})
			})
		}
	})

	 createMap()
})

fetch('/definitions/layers.json').then(async response => {
	features = (await response.json()).filter(l => l.hidden !== true).map(l => {
		return {
			label: l.label,
			tileLayer: new TileLayer({
				visible: false,
				opacity: l.opacity || 1,
				source: new XYZ({
					url: l.url,
					attributions: l.attributions
				})
			})
		}
	})

	 createMap()
})

function createMap() {
	if(basemaps.length === 0 || features.length === 0)
		return
	
	const layers = basemaps.concat(features).concat(icons)
	
	// Pre-select basemap and layer
	const visibleLayers = JSON.parse(localStorage.visibleLayers || "[]")
	for(const l of layers) {
		if(visibleLayers.indexOf(l.label) >= 0) {
			l.tileLayer.setVisible(true)
		}
	}

	// Restore map center
	const center = JSON.parse(localStorage.center || "[24, 65]")
	const zoom = parseInt(localStorage.zoom || "6")
	
	createButtons(document.getElementById("toolbar"), basemaps)
	createButtons(document.getElementById("menu"), features)
	
	// Create temperature display
	tempElement.type = "button"
	//element.role = "switch"
	tempElement.value = "--- C"
	tempElement.className = "info"
	document.getElementById("temp").appendChild(tempElement)
	
	map = new Map({
		layers: layers.map(l => l.tileLayer),
		target: 'map',
		view: new View({
			center: fromLonLat(center),
			zoom: zoom,
			maxZoom: maxZoom,
		}),
		controls: defaultControls({
			attribution: false,
			rotate: false,
			zoom: false
		}).extend([attribution]),
		interactions: defaultInteractions({
			altShiftDragRotate: false,
			pinchRotate: false
		}),
	})
	
	map.on('moveend', onMoveEnd)

	//console.log("MAP done!")
}

function onMoveEnd(evt) {
	const map = evt.map;
	
	// store coordinate
	const extent = map.getView().calculateExtent(map.getSize());
	
	const coord = toLonLat(getCenter(extent));
	localStorage.setItem("center", JSON.stringify(coord))
	
	// store zoom level
	localStorage.setItem("zoom", map.getView().getZoom())
	
	// update temp
	updateTemperature(coord)
}

function createButtons(targetElement, array) {
	for(var i = 0; i < array.length; i++) {
		console.log("Add button")
		var selected = array[i].tileLayer.getVisible()
		addButton(targetElement, "button", array[i].label, selected, function(param) {
			toggleLayer(array, param)
		}, i)
	}
}

function addButton(targetElement, type, text, selected, func, param) {
	var element = document.createElement("input")
	element.type = type
	element.role = "switch"
	element.value = text
	element.className = "button"
	element.onclick = function() {
		func(param)
		element.classList.toggle("button_selected")
	}
	if(selected) {
		element.classList.add("button_selected")
	}

	targetElement.appendChild(element)
}

function toggleLayer(array, index) {
	const visible = !array[index].tileLayer.getVisible()
	array[index].tileLayer.setVisible(visible)
	
	// persist setting
	const label = array[index].label
	const visibleLayers = JSON.parse(localStorage.visibleLayers || "[]")
	
	if(visible && visibleLayers.indexOf(label) < 0) {
		visibleLayers.push(label)
	} else if(visibleLayers.indexOf(label) >= 0) {
		visibleLayers.splice(visibleLayers.indexOf(label), 1)
	}

	localStorage.setItem("visibleLayers", JSON.stringify(visibleLayers))
}

function setLayerVisibility(array, index, visible) {
	array[index].tileLayer.setVisible(visible)
}

function getLocation(callback) {
  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(callback);
  } else {
    x.innerHTML = "Geolocation is not supported by this browser.";
  }
}

function showPosition(position) {
	console.log("Lat/lon: " + position.coords.latitude + ", " + position.coords.longitude + " alt = " + position.coords.altitude)
	iconFeature.getGeometry().setCoordinates(fromLonLat([position.coords.longitude, position.coords.latitude]))
}

function updateTemperature(coord) {
	// TODO: update only if coordinate has moved far enough!
	if(new Date().getTime() - temperatureCoord.getTime() > 1000*60) {
		fetchTemperature(coord[1], coord[0])
		//temperatureCoord = coord
		temperatureCoord = new Date()
	}
}

function fetchTemperature(lat, lon) {
	fetch('https://api.openweathermap.org/data/2.5/weather?lat=' + lat + '&lon=' + lon+ '&APPID=fa83f64ab843f8025b196d17b44e570e').then(async response => {
		const t = await response.json()
		console.log("TEMP: " + JSON.stringify(t))
		
		tempElement.value = "" + t.name + ": " + t.weather[0].description + " " + Math.round(t.main.temp - 273.15) + " °C"
	})
}
